import React, { Component } from "react";
import window from 'global';
import '../styles/share.scss';
//import PropTypes from 'prop-types';
import Helmet from "react-helmet";
import Paper from '@material-ui/core/Paper';
import { css } from "@emotion/core"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout.js"
import SEO from "../components/seo"
import PortfolioProduct from "../components/portfolioproduct"
import DisclaimerReplace from "../components/disclaimerreplace"
import Img from "gatsby-image"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MasterTetherList from "../components/mastertetherlist";
import Icon from '@material-ui/core/Icon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Navlinks from '../components/nav';
import '../styles/ProdTemplate.scss';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ImagePicker from 'react-image-picker';
import 'react-image-picker/dist/index.css'
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { AuthUserContext } from '../components/Session';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const images=[];
const carouselSlidesData = [];

const mineralized = [];
const basic = [];

var webURL = "https://dev.sales-app.cmps.emfluence.com";
//var webURL = window.location.href;


function checkPorts() {
  var pForm = document.getElementsByClassName("ogChecked");
  var portAdd = document.getElementById("mainPortfolioAdd");
  if (pForm.legnth > 0) {
    portAdd.classList.add('active');
  } else {
    //do nothing
  }
} 
function portfolioFormOpen(e) {
  var product = e.currentTarget.getAttribute('pbutton');
  var pForm = document.getElementById(product);
  pForm.classList.add('active');
  //document.getElementById('pForm').reset();
}
class CarouselLeftArrow extends Component {
  render() {
    return (
      <a
        href="#"
        className="carousel__arrow carousel__arrow--left"
        onClick={this.props.onClick}
      >
        <span className="fa fa-2x fa-angle-left" />
      </a>
    );
  }
}

class CarouselRightArrow extends Component {
  render() {
    return (
      <a
        href="#"
        className="carousel__arrow carousel__arrow--right"
        onClick={this.props.onClick}
      >
        <span className="fa fa-2x fa-angle-right" />
      </a>
    );
  }
}

class CarouselIndicator extends Component {
  render() {
    return (
      <li>
        <a
          className={
            this.props.index === this.props.activeIndex
              ? "carousel__indicator carousel__indicator--active"
              : "carousel__indicator"
          }
          onClick={this.props.onClick}
        />
      </li>
    );
  }
}

class CarouselSlide extends Component {
  render() {
    return (
      <li
        className={
          this.props.index === this.props.activeIndex
            ? "carousel__slide carousel__slide--active"
            : "carousel__slide"
        }
      >
        <div  className="video">
          <div className="videoInfo">
            <h3 className='videoTitle' dangerouslySetInnerHTML={{ __html: this.props.slide.title}}/>
            <div className='videoDescription' dangerouslySetInnerHTML={{ __html: this.props.slide.description}}/>
          </div>
          <video 
            className="galleryVideo"
            poster={this.props.slide.poster}
            src={this.props.slide.url}
            title={this.props.slide.title}
            controls="true"
            playsinline
            webkit-playsinline
            crossOrigin="anonymous"
            type="video/mp4"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            preload="auto"
            >
            </video>
          {/*<video
            className="galleryVideo"
            poster={this.props.slide.poster}
            src={this.props.slide.url}
            title={this.props.slide.title}
            controls
            type='video/mp4'
            frameBorder="0"
            webkit-playsinline="true"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            preload="none"
            track={this.props.slide.title}
          />*/}
        </div>
        <Link>
          <button value={'shareDialogV'+this.props.index} id={'deviceShareV'+this.props.index} type="button" className="icon-share" title={'Share '+this.props.slide.title} data-file={webURL+this.props.slide.url} onClick={openShareDialog}/>            
        </Link>
        <div id={'shareDialogV'+this.props.index} className="share-dialog">
          <button value={'shareDialogV'+this.props.index} onClick={closeShareDialog} className="boxClose"></button>
          <div className="inner">
            <header>
              <h3 className="dialog-title">Share {this.props.slide.title}</h3>
            </header>
            <button value={'shareDialogV'+this.props.index} className="close-button" onClick={closeShareDialog}></button>
            <div className="targets">
              <a className="button" href={'mailto:?body='+webURL+this.props.slide.url}>
                <svg>
                  <use href="#email"></use>
                </svg>
                <span>Email</span>
              </a>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

// Carousel wrapper component
class Carousel extends Component {
  constructor(props) {
    super(props);

    this.goToSlide = this.goToSlide.bind(this);
    this.goToPrevSlide = this.goToPrevSlide.bind(this);
    this.goToNextSlide = this.goToNextSlide.bind(this);

    this.state = {
      activeIndex: 0
    };
  }

  goToSlide(index) {
    this.setState({
      activeIndex: index
    });
    const galleryVideo = document.getElementsByClassName('galleryVideo');
    galleryVideo[index].pause()
  }

  goToPrevSlide(e) {
    e.preventDefault();
    
    let index = this.state.activeIndex;
    let { slides } = this.props;
    let slidesLength = slides.length;
    const galleryVideo = document.getElementsByClassName('galleryVideo');
    galleryVideo[index].pause()
    if (index < 1) {
      index = slidesLength;
    }

    --index;

    this.setState({
      activeIndex: index,
      
    });
  }

  goToNextSlide(e) {
    e.preventDefault();

    let index = this.state.activeIndex;
    let { slides } = this.props;
    let slidesLength = slides.length - 1;
    const galleryVideo = document.getElementsByClassName('galleryVideo');
    galleryVideo[index].pause()
    if (index === slidesLength) {
      index = -1;
    }

    ++index;

    this.setState({
      activeIndex: index
    });
  }

  render() {
    return (
      <div className="carousel fCol12">
        {carouselSlidesData.length > 1 &&
        <CarouselLeftArrow onClick={e => this.goToPrevSlide(e)} />
        }
        <ul className="carousel__slides">
          {this.props.slides.map((slide, index) =>
            <CarouselSlide
              key={index}
              index={index}
              activeIndex={this.state.activeIndex}
              slide={slide}
            />
          )}
        </ul>
        {carouselSlidesData.length > 1 &&
          <CarouselRightArrow onClick={e => this.goToNextSlide(e)} />
        }
        {carouselSlidesData.length > 1 &&
          <ul className="carousel__indicators">
            {this.props.slides.map((slide, index) =>
              <CarouselIndicator
                key={index}
                index={index}
                activeIndex={this.state.activeIndex}
                isActive={this.state.activeIndex==index} 
                onClick={e => this.goToSlide(index)}
              />
            )}
          </ul>
        }
      </div>
    );
  }
}


export class SelectGallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image: null,
      images: []
    }
  }

  onPickImages(images) {
    this.setState({images})
  }
  removeCloseSelectImg = () => {
    console.log('Click happened');
    //this.state.images.length = 0;
    //this.setState({ images: [] });
    this.setState({ image:null });
    this.setState({ images: [] });
    var element = document.getElementById("selectWrapperImage");
    var imgLite = document.getElementById("lightboxWrapper");
    var imgShareIcn = document.getElementById("shareGalIcon");
    if (element.classList.contains('active-selects')) {
      element.classList.remove('active-selects');
      element.classList.add('inactive-selects');

    } else {
      element.classList.add('active-selects');
      element.classList.remove('inactive-selects');

    }
    if (imgLite.classList.contains('active-selects')) {
      imgLite.classList.remove('active-selects');
      imgLite.classList.add('inactive-selects');
    } else {
      imgLite.classList.add('active-selects');
      imgLite.classList.remove('inactive-selects');
    }
    if (imgShareIcn.classList.contains('active-shareICN')) {
      imgShareIcn.classList.remove('active-shareICN');
      imgShareIcn.classList.add('inactive-shareICN');    
    } else {
      imgShareIcn.classList.add('active-shareICN');
      imgShareIcn.classList.remove('inactive-shareICN');
    }
    var imgPickerResp = document.getElementsByClassName("responsive");
    for(var i = 0, length = imgPickerResp.length; i < length; i++) {   
      if( imgPickerResp[i].textContent == ''){
        imgPickerResp[i].classList.remove('selected');
        //imgTest[i].style.display = 'none';
      }     
    }
    var imgPickThumb = document.getElementsByClassName("thumbnail");
    for(var i = 0, length = imgPickThumb.length; i < length; i++) {   
      if( imgPickThumb[i].textContent == ''){
        imgPickThumb[i].classList.remove('selected');
        //imgTest[i].style.display = 'none';
      }     
    }
  }
  render() {
    return (
      <div>
        <React.Fragment>
          <span id="shareGalIcon" className="icons inactive-shareICN">
            <div className="galleryShare">
              <button value={'shareDialogGal'} id={'deviceShareGal'} type="button"  title={'Share '} data-file={this.state.images.map((item) => webURL+item.src)} onClick={openShareDialog}>Share Selected <span className="icon-share"> </span></button>            
              <button className="shareCancel" onClick={this.removeCloseSelectImg}>Cancel</button>
            </div>
          </span>  
        </React.Fragment>

        <React.Fragment>
          <ImagePicker 
            images={images.map((image, i) => ({src: image.lbImage, value: i}))}
            onPick={this.onPickImages.bind(this)}
            multiple
          />
          {/*<textarea rows="4" cols="100" value={this.state.images && JSON.stringify(this.state.images)} disabled/>*/}

          <div id={'shareDialogGal'} className="share-dialog">
            <button value={'shareDialogGal'} onClick={closeShareDialog} className="boxClose"></button>
            <div className="inner">
              <header>
                <h3 className="dialog-title">Share Images</h3>
              </header>
              <button value={'shareDialogGal'} className="close-button" onClick={closeShareDialog}></button>
              <div className="targets">
                <a className="button" href={('mailto:?subject=Check out these images&body=Check out these images:%0D%0A%0D%0A'+this.state.images.map((item) => webURL+item.src+'%0D%0A')).replace(/,/g, '')}>
                  <svg>
                    <use href="#email"></use>
                  </svg>
                  <span>Email</span>
                </a>
                
                <div className="hide">
                <ul>
                {this.state.images.map((item, value) => (
                  <React.Fragment>
                  <li className={'product-carried-'+value}>{webURL+item.src}</li>                   
                  </React.Fragment>
                ))}
                </ul>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}


export class LightboxGallery extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  onPickImages(images) {
    this.setState({images})
  }
 
  render() {
    const { photoIndex, isOpen } = this.state;
    const { pID} = this.props;
    return (
      <div>
        {images.map((image, index) => (
          <React.Fragment>
            <div id={'lbItem-'+pID+index} className="galleryItem" key={pID+index}>
                <img id={'lbThumbnail-'+pID+index} className="lightboxThumbnail" src={ image.lbImage } onClick={() => this.setState({ isOpen: true, photoIndex: index })}/>
            </div>
          </React.Fragment>
        ))}
        {isOpen && (
          <Lightbox
            imageCaption= {images[photoIndex].lbCaption}
            mainSrc={images[photoIndex].lbImage}
            nextSrc={images[(photoIndex + 1) % images.length].lbImage}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].lbImage}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

function saveAsPDF(content) {
  // fake server request, getting the file url as response
  var dloFile = content.currentTarget.getAttribute('data-file');
  if (window.matchMedia('(display-mode: standalone)').matches)  {
    var windowReference = window.open();
    windowReference.location = dloFile;
  }
  else {
    window.location.href = dloFile;
  }
}

function tabNavigation(e) {
  var clickedTab = e.currentTarget.getAttribute('data-tab');
  var clickedContent = e.currentTarget.getAttribute('data-content');
  var clickedMobile = e.currentTarget.getAttribute('data-mob-tab');
  var currentTab = document.getElementById(clickedTab);
  //var currentContent = document.getElementById(clickedContent);

  var tabAdd = document.getElementById('addTab');
  var cAdd = document.getElementById('additional-content');

  if (!currentTab.classList.contains('active')) {
      var homeContent = document.getElementById('content-home');
      if (clickedContent != 'additional-content') {
        if (clickedContent !== 'content-home') {
          homeContent.classList.remove('active');
        }else {
          homeContent.classList.add('active');
        }

        var tabContents = document.getElementsByClassName('secondaryTab');
        for (var i = 0; i < tabContents.length; i++) {
            if (tabContents[i].id !== clickedContent) {
                tabContents[i].classList.remove('active');
            } else {
                tabContents[i].classList.add('active');
            }
        }

        var tabs = document.getElementsByClassName('tab');
        for (var i = 0; i < tabs.length; i++) {
            if (tabs[i].id !== clickedTab) {
                tabs[i].classList.remove('active');
            } else {
                tabs[i].classList.add('active');
            }
        }
        cAdd.classList.remove('expand');
        var pForm = document.getElementsByClassName("portfolioPopup");
        for (var ipf = 0; ipf < pForm.length; ipf++) {
          pForm[ipf].classList.remove('active');
        }
        
      }
      

      if (clickedMobile === 'true') {
          tabAdd.classList.add('active')
      } else if (clickedTab !== 'tabAdd') {
          tabAdd.classList.remove('active')
      }
      const galleryVideo = document.getElementsByClassName('galleryVideo');
      Array.prototype.forEach.call(galleryVideo, function(videoP) {
      videoP.pause();
      });
      window.scrollTo(0, 0);
      var tabsContainer = document.getElementById("tabsContainer");
      tabsContainer.scrollTo(0, 0);

      //close Warning and FAQ Expands
      const warning = document.getElementById("uWarning");
      const faqs = document.getElementsByClassName('faq');
      const faqSection = document.getElementById('uFaqs');
      const faqButton = document.getElementById("faqMore");
      if (faqs !=null){
        Array.prototype.forEach.call(faqs, function(faqAll) {
          faqAll.classList.remove('active');
        });
      }
      if (warning !=null){
        warning.classList.remove('expand');
      }
      if (faqSection !=null){
        faqSection.classList.remove('expand');
      }
      if (faqButton !=null){
        faqButton.classList.remove('expand');
      }
      
  }
  if (clickedContent === 'additional-content') {
    if (cAdd.classList.contains('expand')) {
        cAdd.classList.remove('expand');
    } else {
        cAdd.classList.add('expand');
    }
  }
}

function closeMenu() {
  var element = document.getElementById("additional-content");
  if (element.classList.contains('expand')) {
    element.classList.remove('expand');
  } else {
    
  }
}

function warningExpand() {
  var element = document.getElementById("uWarning");
  if (element.classList.contains('expand')) {
    element.classList.remove('expand');
  } else {
    element.classList.add('expand');
  }
}

function faqMore() {
  var element = document.getElementById("uFaqs");
  var faqButton = document.getElementById("faqMore");
  if (element.classList.contains('expand')) {
    element.classList.remove('expand');
    const faqs = document.getElementsByClassName('faq');
    Array.prototype.forEach.call(faqs, function(faqAll, index) {
      if (index > 2) {
        faqAll.classList.remove('active');
      }
    });
  } else {
    element.classList.add('expand');
  }
  if (faqButton.classList.contains('expand')) {
    faqButton.classList.remove('expand');
  } else {
    faqButton.classList.add('expand');
  }
}


function selectImageCollapse() {
  var element = document.getElementById("selectWrapperImage");
  var imgLite = document.getElementById("lightboxWrapper");
  var imgShareIcn = document.getElementById("shareGalIcon");
  if (element.classList.contains('active-selects')) {
    element.classList.remove('active-selects');
    element.classList.add('inactive-selects');

  } else {
    element.classList.add('active-selects');
    element.classList.remove('inactive-selects');

  }
  if (imgLite.classList.contains('active-selects')) {
    imgLite.classList.remove('active-selects');
    imgLite.classList.add('inactive-selects');
  } else {
    imgLite.classList.add('active-selects');
    imgLite.classList.remove('inactive-selects');
  }
  if (imgShareIcn.classList.contains('active-shareICN')) {
    imgShareIcn.classList.remove('active-shareICN');
    imgShareIcn.classList.add('inactive-shareICN');    
  } else {
    imgShareIcn.classList.add('active-shareICN');
    imgShareIcn.classList.remove('inactive-shareICN');
  }
  var imgPickerResp = document.getElementsByClassName("responsive");
  for(var i = 0, length = imgPickerResp.length; i < length; i++) {   
    if( imgPickerResp[i].textContent === ''){
      imgPickerResp[i].classList.remove('selected');
      //imgTest[i].style.display = 'none';
    }     
  }
  var imgPickThumb = document.getElementsByClassName("thumbnail");
  for(var i = 0, length = imgPickThumb.length; i < length; i++) {   
    if( imgPickThumb[i].textContent === ''){
      imgPickThumb[i].classList.remove('selected');
      //imgTest[i].style.display = 'none';
    }     
  }
  //var productList = document.querySelector('#selectWrapperImage .image_picker .responsive.selected').children;
  //console.log('thumbs: ', productList);
}

function faqCollapse(e) {
  const faq = document.getElementById(e.currentTarget.value);
  if (faq.classList.contains('active')) {
    faq.classList.remove('active');
  } else {
    const faqs = document.getElementsByClassName('faq');
    Array.prototype.forEach.call(faqs, function(faqAll) {
      faqAll.classList.remove('active');
    });
    faq.classList.add('active');
  }
}

function pdfOpen(e) {
  const pdfViewer = document.getElementById(e.currentTarget.value);
  if (pdfViewer.classList.contains('active')) {
    pdfViewer.classList.remove('active');
  } else {
    /*const pdfViewers = document.getElementsByClassName('pdfViewer');
    Array.prototype.forEach.call(pdfViewers, function(pdfAll) {
      pdfAll.classList.remove('active');
    });*/
    pdfViewer.classList.add('active');
  }
}

function pdfClose(e) {
  const pdfViewer = document.getElementById(e.currentTarget.value);
  if (pdfViewer.classList.contains('active')) {
    pdfViewer.classList.remove('active');
  }
}

class Pdfviewer extends Component {
  state = { numPages: null, pageNumber: 1, scaleControl: 1};
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  goToNextPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber + 1 }));
  zoomIn = () =>
    this.setState(state => ({ scaleControl: state.scaleControl + .5 }));
  zoomOut = () =>
    this.setState(state => ({ scaleControl: state.scaleControl - .5 }));
  zoomReset = () =>
    this.setState(state => ({ scaleControl: 1 }));

  render() {
    const { pageNumber, numPages, scaleControl } = this.state;
    var intFrameHeight = window.innerHeight;
    var intFrameWidth = window.innerWidth;
    if (intFrameWidth > intFrameHeight) {
      intFrameWidth = intFrameHeight / 1.2 - 75;
    }
    if (intFrameHeight > intFrameWidth) {
      intFrameHeight = intFrameWidth / 1.1 - 75;
      intFrameWidth = intFrameWidth / 1.1 - 75;
    }
    return (
      <div id={this.props.id} className={this.props.className}>
        
        <button value={this.props.id} className="pdfClose" onClick={pdfClose}></button>
        <button value={this.props.id} className="pdfCloseX" onClick={pdfClose}></button>
        <div className="pdfBox">
          <Document
            file={this.props.file}
            onLoadSuccess={this.onDocumentLoadSuccess}
            height={intFrameHeight}
          >
            <Page pageNumber={pageNumber} scale={scaleControl} width={intFrameWidth}/>
          </Document>
        </div>
        <nav>
          {pageNumber > 1 &&
            <button className="prevArrow" onClick={this.goToPrevPage}></button>
          }
          {pageNumber <= 1 &&
            <button className="prevArrow disabled"></button>
          }
          <div className="pageNumbers">{pageNumber}/{numPages}</div>
          {pageNumber < numPages &&
            <button className="nextArrow" onClick={this.goToNextPage}></button>
          }
          {pageNumber >= numPages &&
            <button className="disabled nextArrow"></button>
          }
          {scaleControl > .5 &&
            <button className="zoomIcon" onClick={this.zoomOut}><Icon>zoom_out</Icon></button>
          }
          {scaleControl <= .5 &&
            <button className="zoomIcon disabled"><Icon>zoom_out</Icon></button>
          }
          {scaleControl <= 3 &&
            <button className="zoomIcon" onClick={this.zoomIn}><Icon>zoom_in</Icon></button>
          }
          {scaleControl > 3 &&
            <button className="zoomIcon disabled"><Icon>zoom_in</Icon></button>
          }
        </nav>
       
      </div>
    );
  }
}

function openShareDialog(e) {
  var shareDialog = document.getElementById(e.currentTarget.value);
  var shareTitle = e.currentTarget.title;
  var shareFile = e.currentTarget.getAttribute('data-file');
  e.preventDefault();
  //shareDialog.classList.add('is-open');
  if (navigator.share) {
      navigator.share({
        title: shareTitle,
        url: shareFile
      }).then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
    } else {
      shareDialog.classList.add('is-open');
    }
    
}



function closeShareDialog(e) {
  var shareDialog = document.getElementById(e.currentTarget.value);
  e.preventDefault();
  shareDialog.classList.remove('is-open');
  console.log('The link was clicked.');
}

function disclaimerPdfOpen(element) {
    const pdfViewer = document.getElementById(document.getElementById(element).getAttribute('value'));
    if (pdfViewer.classList.contains('active')) {
      pdfViewer.classList.remove('active');
    } else {
      pdfViewer.classList.add('active');
    }
}
const ProdTemplate = ({ data }) => {
  
  return(
    <Layout>
      <SEO
        title={data.wordpressPage.yoast_meta.yoast_wpseo_title.replace(/(<([^>]+)>)/ig, '')}
        description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc.replace(/(<([^>]+)>)/ig, '')}
      />
    <Helmet>
      <meta name="format-detection" content="telephone=no" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <body className="product" />
    </Helmet>
    <AuthUserContext.Consumer>
        {authUser =>
            authUser ? (
              <Helmet>
                <meta name="format-detection" content="telephone=no" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <body className="product" />
              </Helmet>
            ) : (
              <Helmet>
                <meta name="format-detection" content="telephone=no" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <body className="product loggedOut" />
              </Helmet>
            )
        }
    </AuthUserContext.Consumer>
    <header className="mainHead flexContainer faCenter fjCenter" css={css`
       background: ${data.wordpressPage.parent_element.acf.brand_color};
    `}>
    <div className="fCol12 menuCtrl">
      <Link className="headerTitle" to={`/${data.wordpressPage.parent_element.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/`}>
      
      <span className="arrowbk"><Icon>arrow_back</Icon></span><span className="mobileShow">Back</span><span className="mobileHide" dangerouslySetInnerHTML={{ __html: data.wordpressPage.parent_element.title}}/>
      </Link>

      {/* Nav Side */}

      <Navlinks />
    </div>
    </header>
      <div id="tabsContainer" className="tabsContainer" css={css`margin-top:5vh;`}>
        <section id="content-home" className="content-home active">
          <div className="flexContainer hero">
            {data.wordpressPage.acf.prod_hero_override !=null &&
              <div css={css`opacity:${data.wordpressPage.acf.prod_hero_opacity}`}>
                <Img className="fCol12 heroImage"
                    sizes={data.wordpressPage.acf.prod_hero_override.localFile.childImageSharp.sizes} 
                />
              </div>
            }
            {data.wordpressPage.acf.prod_hero_override === null &&
              <div css={css`opacity:${data.wordpressPage.acf.prod_hero_opacity}`}>
                <Img className="fCol12 heroImage"
                    sizes={data.wordpressPage.parent_element.acf.brand_hero.localFile.childImageSharp.sizes} 
                />
              </div>
            }
            <div className="fCol12 bagImage">
              <div className="maxTablet flexContainer fjLeft">
                {data.wordpressPage.acf.product_bag != null &&
                  <Img className="fCol12 fCol7-xs fCol6-sm"
                    sizes={data.wordpressPage.acf.product_bag.localFile.childImageSharp.sizes}
                  />
                }
              </div>
            </div>

     
             
            <div className={'fCol12 brandLogo-p '+data.wordpressPage.acf.prod_brand_logo_position.value}>
              <div className="maxTablet flexContainer fjRight faTop">
                {data.wordpressPage.acf.prod_brand_logo_override != null &&
                <Img className="fCol4 logo_override"
                  sizes={data.wordpressPage.acf.prod_brand_logo_override.localFile.childImageSharp.sizes}
                />
                }
                {data.wordpressPage.acf.prod_brand_logo_override === null &&
                <Img className="fCol4"
                  sizes={data.wordpressPage.parent_element.acf.brand_logo.localFile.childImageSharp.sizes}
                />
                }
              </div>
            </div>
            <div className="fCol12 productName" css={css` background-color: ${data.wordpressPage.acf.product_color}; `}>
              <div className="maxTablet flexContainer">
                <h1 className="fCol12 fCol7-xs fCol8-sm" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.product_name}} />
              </div>
            </div>
            <div className="fCol12 productPortfolioButton">
              <div className="maxTablet flexContainer">
                <div id={'mainPortfolioAddportfolioPopupOg'+data.wordpressPage.id} className="portfolioAdd" pbutton={'portfolioPopupOg'+data.wordpressPage.id} onClick={portfolioFormOpen}>
                  <span className="icon-star-unfill">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                  <span className="icon-star-full">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                  <span className="icon-select-bars"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="flexContainer faStretch maxTablet classification">
            <div className="fCol12">
              {data.wordpressPage.acf.product_class.value !='none' &&
              <div dangerouslySetInnerHTML={{ __html: '<span class="cValue '+data.wordpressPage.acf.product_class.value+'"><span class="inner">'+data.wordpressPage.acf.product_class.value+'</span></span><span class="cLabel '+data.wordpressPage.acf.product_class.value+'">'+data.wordpressPage.acf.product_class.label+'</span>' }} />
              }
            </div>
          </div>
          <div className="grayBorderBottom">
            <div className="flexContainer faStretch maxTablet">  
              <div className="fCol12 fCol7-xs fCol8-md grayBorderRight" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.product_copy }} />
              <div className="fCol12 fCol5-xs fCol4-md talkingPoints">
                <ul>
                  {data.wordpressPage.acf.product_points_rp.map((point, i) => (
                    <React.Fragment>
                      <li key={'prodPoint'+i} css={css`
                        color: ${data.wordpressPage.parent_element.acf.brand_color};
                      `}
                      dangerouslySetInnerHTML={{ __html: point.product_point }}
                      />
                    </React.Fragment>
                  ))}
                </ul>
                {data.wordpressPage.acf.product_points_fp !=null &&
                  <div className="prodcutTalkingPoints_finePrint" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.product_points_fp}} />
                }
              </div>
            </div>
          </div>
          <div className="grayBorderBottom">
            <div className="flexContainer maxTablet ingredients">
              <div className="fCol12">
                <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.product_ingred_headline }}/>
                <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.product_ingred }} />
              </div>
            </div>
          </div>
          <div>
            <div className="flexContainer faStretch maxTablet">
                {data.wordpressPage.acf.prod_sizes_page.map(size => (
                  <React.Fragment>
                    <div className={'fCol12 flexContainer prodSizes fjLeft grayBorderBottom '+data.wordpressPage.acf.product_show_size}>
                      <h3>{size.prod_fets_header}</h3>
                      <ul className="flexContainer fjLeft faBottom">
                      {size.prod_select_icons.map(icon => (
                        <React.Fragment>
                        <li className={icon.prod_icon+' fCol4'}> {icon.prod_icon_txt}</li>    
                        </React.Fragment>
                      ))}                
                      </ul>
                      <span className="warning" css={css`color: ${data.wordpressPage.parent_element.acf.brand_color};`}>{size.prod_fet_warning}</span>
                    </div>
                  </React.Fragment>          
                ))}



                {data.wordpressPage.acf.prod_species_page !=null && data.wordpressPage.acf.prod_species_page.map(species => (
                  <React.Fragment>
                    <div className="fCol12 fCol6-xs grayBorderLeft prodSpecies">
                      <h3>{species.prod_fets_header}</h3>
                      <ul className="flexContainer fjLeft">
                        {species.prod_select_icons.map(icon => (
                          <React.Fragment>
                          <li className={icon.prod_icon+' '+icon.prod_icon_status+' fCol4 fCol4-xs'}> {icon.prod_icon_txt}</li>    
                          </React.Fragment>
                        ))}                
                      </ul>
                      <span className="warning" css={css`color: ${data.wordpressPage.parent_element.acf.brand_color};`}>{species.prod_fet_warning}</span>
                    </div>
                  </React.Fragment>          
                ))}
            </div>
          </div>
        </section>
        <section id="content-spec" className="content-spec secondaryTab">
          <div className="fCol12 productName" css={css` background-color: ${data.wordpressPage.acf.product_color}; `}>
            <div className="maxTablet">
              <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.product_name+' Specifications'}} />
            </div>
          </div>
          {data.wordpressPage.acf.prod_input_specs_page !=null && 
          <div className="grayBorderBottom">
            <div className="flexContainer maxTablet gAnalysis">
              <div className="fCol12">
                {data.wordpressPage.acf.prod_input_specs_page !=null && data.wordpressPage.acf.prod_input_specs_page.map(tbl => (
                <React.Fragment>
                  <h3>{tbl.prod_table_header}</h3>
                  <table>
                    <tbody>
                      {tbl.prod_tbl_two_rep.map(trow => (
                        <React.Fragment>
                          <tr>
                            <td dangerouslySetInnerHTML={{ __html: trow.prod_tbl_two_row.prod_tbl_two_frst_col}}></td>
                            <td dangerouslySetInnerHTML={{ __html: trow.prod_tbl_two_row.prod_tbl_two_sec_col}}></td>
                          </tr>
                        </React.Fragment>    
                      ))} 
                    </tbody>
                  </table>
                </React.Fragment>          
                ))}
              </div>
              </div>
          </div>
          }
          <div className="grayBorderBottom">
            <div className="flexContainer maxTablet ingredients">
              <div className="fCol12">
                <h3>Ingredients</h3>
                <p dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.product_ingred }} />
              </div>
            </div>
          </div>
          <div className="grayBorderBottom">
          {data.wordpressPage.acf.prod_pkg_tables.map(pkgTable => (
            <React.Fragment>
                <div className="flexContainer maxTablet">
                  <div className="fCol12">
                    <h3 dangerouslySetInnerHTML={{ __html: pkgTable.prod_pkg_header}}/>
                      <div className="stackedTable">
                          <div className="thRow tRow">
                            {pkgTable.prod_pkg_table.header.map(headerRow => (
                              <React.Fragment>
                                <div dangerouslySetInnerHTML={{ __html: headerRow.c}}></div>
                              </React.Fragment>    
                            ))}
                          </div>
                          {pkgTable.prod_pkg_table.body.map(bodyRow => (
                          <div className="tbRow tRow">
                            <React.Fragment>
                              {bodyRow.map((bodyCol,index) => (
                                <React.Fragment>
                                  <div className="tCol" dangerouslySetInnerHTML={{ __html: '<span class="mHeading">'+pkgTable.prod_pkg_table.header[index].c+'</span><span class="mItem">'+bodyCol.c+'</span>'}}></div>
                                </React.Fragment>
                              ))}
                            </React.Fragment>    
                          </div>
                          ))}
                      </div>       
                  </div>
                </div>
            </React.Fragment>    
          ))}
          </div>
        </section>
        {data.wordpressPage.acf.prod_add_data_sheets != null &&
          <section id="content-data" className="content-data secondaryTab">
            <div className="fCol12 productName" css={css` background-color: ${data.wordpressPage.acf.product_color}; `}>
              <div className="maxTablet">
                <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.product_name+' Data Sheets'}} />
              </div>
            </div>
            {data.wordpressPage.acf.prod_add_data_sheets != null && data.wordpressPage.acf.prod_add_data_sheets.map((dataSheet,index) => (
              <div>
                <React.Fragment>
                  <div className="grayBorderBottom">
                    <div className="flexContainer maxTablet">
                      <div className="fCol12 flexContainer faCenter">
                        <div className="dIcon">
                        <button value={'pdfViewerU'+index} onClick={ pdfOpen }>
                          <div className={'icon-'+dataSheet.prod_upload_data_sheet.subtype+' icon'}></div>
                              VIEW        
                        </button>
                        </div>
                        <div className='dName' dangerouslySetInnerHTML={{ __html: dataSheet.prod_data_sheet_name+'<br/>'+dataSheet.prod_data_sheet_name_line+'<br/>'+dataSheet.prod_data_sheet_name_line_3}}></div>
                        <div className='dButtons'>
                          <a href="#">
                            <button value={'shareDialogU'+index} id={'deviceShareU'+index} type="button" className="grayButton" title={'Share '+dataSheet.prod_data_sheet_name} data-file={dataSheet.prod_upload_data_sheet.url.localFile.publicURL} onClick={openShareDialog}>Share</button>            
                          </a>

                          <a onClick={saveAsPDF} data-file={dataSheet.prod_data_sheet_zip_download.url.localFile.publicURL}><button  className="grayButton">Download</button></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Pdfviewer id={'pdfViewerU'+index} className="pdfViewer" file={dataSheet.prod_upload_data_sheet.url.localFile.publicURL}/>
                  <div id={'shareDialogU'+index} className="share-dialog">
                  <button value={'shareDialogU'+index} onClick={closeShareDialog} className="boxClose"></button>
                  <div className="inner">
                    <header>
                      <h3 className="dialog-title">Share {dataSheet.prod_data_sheet_name}</h3>
                    </header>
                    <button value={'shareDialogU'+index} className="close-button" onClick={closeShareDialog}></button>
                    <div className="targets">
                      <a className="button" href={'mailto:?body='+webURL+dataSheet.prod_upload_data_sheet.url.localFile.publicURL}>
                        <svg>
                          <use href="#email"></use>
                        </svg>
                        <span>Email</span>
                      </a>
                    </div>
                  </div>
                </div>
                </React.Fragment>    
              </div>
            ))}
          </section>
        }
        {data.wordpressPage.acf.prod_add_data_sheets === null &&
          <section id="content-data" className="content-data secondaryTab"></section>
        }
        {data.wordpressPage.acf.prod_img_galy !=null &&
        <section id="content-gallery" className="content-gallery secondaryTab">
          <div className="fCol12 productName" css={css` background-color: ${data.wordpressPage.acf.product_color}; `}>
            <div className="maxTablet">
              <div>
                <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.product_name+' Imagery'}} />
              </div>
              
            </div>

          </div>
          <div className="fCol12 maxTablet flexContainer fjLeft">
          <p className="fCol12 portalText" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.prod_img_galy_portal}} />
            <div className="hide">
              {images.length = 0}
            </div>
            {data.wordpressPage.acf.prod_img_galy !=null && data.wordpressPage.acf.prod_img_galy.map(galy => (
              <React.Fragment>
                {galy.localFile != null &&
                <div className="hide">
                  {images.push({"lbImage": galy.localFile.publicURL,"lbCaption": galy.caption.replace(/(<([^>]+)>)/ig,"").replace("&#8217;", "'")})}
                </div>
                }
                
              </React.Fragment>  
            ))}
            
            <div id="lightboxWrapper" className="lightboxWrapper fCol12 active-selects">
              <div className="galleryShare single">
                <a className="toggleShareSelect" onClick={selectImageCollapse}>SELECT IMAGE(S) TO SHARE</a>
                {/*<button className="icon-share" onClick={selectImageCollapse}>Share</button>*/}
              </div>
              <LightboxGallery pID={data.wordpressPage.slug}/>
            </div>
            <div id="selectWrapperImage" className="fCol12 selectWrapperImage inactive-selects" >
              <SelectGallery />
            </div>
          </div>
        </section>
        }
        {data.wordpressPage.acf.prod_img_galy ==null && <section id="content-gallery" className="content-gallery secondaryTab"></section>}
        <section id="content-proof" className="content-proof secondaryTab">

        </section>
        <section id="content-usage" className="content-usage secondaryTab">
          <div className="fCol12 productName" css={css` background-color: ${data.wordpressPage.acf.product_color}; `}>
            <div className="maxTablet">
              <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.product_name+' Usage'}} />
            </div>
          </div>
          <div className="flexContainer maxTablet usageTypes">
            {data.wordpressPage.childWordPressAcfProdUsgInstructions != null &&
            <div className="fCol12 uInstructions">
              <h3>{data.wordpressPage.childWordPressAcfProdUsgInstructions.prod_usg_instructions_header}</h3>
              <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.childWordPressAcfProdUsgInstructions.prod_usg_instructions_copy }} />
            </div>
            }
            {data.wordpressPage.childWordPressAcfProdUsageTable != null &&
              <React.Fragment>
                <div className="fCol12 uTable">
                {data.wordpressPage.childWordPressAcfProdUsageTable.prod_usage_table_header != null && 
                <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.childWordPressAcfProdUsageTable.prod_usage_table_header }}/>}
                {data.wordpressPage.childWordPressAcfProdUsageTable.prod_usage_table_before != null && 
                <div className="tableBefore" dangerouslySetInnerHTML={{ __html: data.wordpressPage.childWordPressAcfProdUsageTable.prod_usage_table_before }}/>}
                    <div className="stackedTable">
                        <div className="thRow tRow">
                          {data.wordpressPage.childWordPressAcfProdUsageTable.prod_usage_table_table.header.map(headerRow => (
                            <React.Fragment>
                              <div dangerouslySetInnerHTML={{ __html: headerRow.c}}></div>
                            </React.Fragment>    
                          ))}
                        </div>
                        {data.wordpressPage.childWordPressAcfProdUsageTable.prod_usage_table_table.body.map(bodyRow => (
                        <div className="tbRow tRow">
                          <React.Fragment>
                            {bodyRow.map((bodyCol,index) => (
                              <React.Fragment>
                                <div className="tCol" dangerouslySetInnerHTML={{ __html: '<span class="mHeading">'+data.wordpressPage.childWordPressAcfProdUsageTable.prod_usage_table_table.header[index].c+'</span><span class="mItem">'+bodyCol.c+'</span>'}}></div>
                              </React.Fragment>
                            ))}
                          </React.Fragment>    
                        </div>
                        ))}
                    </div>   
                {data.wordpressPage.childWordPressAcfProdUsageTable.prod_usage_table_after != null && 
                <div className="tableAfter" dangerouslySetInnerHTML={{ __html: data.wordpressPage.childWordPressAcfProdUsageTable.prod_usage_table_after }}/>}    
                </div>
              </React.Fragment>
            }
            {/*data.wordpressPage.childWordPressAcfProdWarnLbl != null &&
            <div className="fCol12">
              <ExpansionPanel className="fCol12 uWarning">
                <ExpansionPanelSummary
                  expandIcon={<div></div>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>{data.wordpressPage.childWordPressAcfProdWarnLbl.prod_warn_lbl_header}</h3>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.childWordPressAcfProdWarnLbl.prod_warn_lbl_copy }} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
            */}
            {data.wordpressPage.childWordPressAcfProdWarnLbl != null &&
              <div id="uWarning" className="fCol12 uWarning" onClick={warningExpand}>
                <h3>{data.wordpressPage.childWordPressAcfProdWarnLbl.prod_warn_lbl_header}</h3>
                <div className="warningExpand" dangerouslySetInnerHTML={{ __html: data.wordpressPage.childWordPressAcfProdWarnLbl.prod_warn_lbl_copy }} />
              </div>
            }
          </div>
          {data.wordpressPage.childWordPressAcfProdFaq != null &&
          <div id="uFaqs" className="uFaqs">
            <div className="flexContainer maxTablet">
              <h3 className="fCol12" dangerouslySetInnerHTML={{ __html: data.wordpressPage.childWordPressAcfProdFaq.prod_faq_header }} />
              {/*data.wordpressPage.childWordPressAcfProdFaq.prod_faq_qa.map((faq,index) => (
                <div id={'faq'+index} className={'faq fCol12'} >
                  <React.Fragment>
                    <ExpansionPanel className="fCol12">
                      <ExpansionPanelSummary
                        expandIcon={<div></div>}
                        aria-controls={'answer'+index}
                        id={'question'+index}
                      >
                        <div className='question' dangerouslySetInnerHTML={{ __html: '<strong>'+faq.prod_faq_qa_question+'</strong>'}}/>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <div className='answer' dangerouslySetInnerHTML={{ __html: faq.prod_faq_qa_answer}}/>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </React.Fragment>    
                </div>
              ))*/}
              {data.wordpressPage.childWordPressAcfProdFaq.prod_faq_qa.map((faq,index) => (
                  index <3 &&
                  <React.Fragment>
                    <div id={'faq'+index} className={'faq fCol12'} >
                    <button value={"faq"+index} id={'question'+index} className='question' dangerouslySetInnerHTML={{ __html: '<strong>'+faq.prod_faq_qa_question+'</strong>'}} onClick={faqCollapse}/>
                    <div className='answer' dangerouslySetInnerHTML={{ __html: faq.prod_faq_qa_answer}}/>
                    </div>
                  </React.Fragment>    
                
              ))}
              <div className="expandedFaqs">
              {data.wordpressPage.childWordPressAcfProdFaq.prod_faq_qa.map((faq,index) => (
                  index >2 &&
                  <React.Fragment>
                    <div id={'faq'+index} className={'faq fCol12'} >
                    <button value={"faq"+index} id={'question'+index} className='question' dangerouslySetInnerHTML={{ __html: '<strong>'+faq.prod_faq_qa_question+'</strong>'}} onClick={faqCollapse}/>
                    <div className='answer' dangerouslySetInnerHTML={{ __html: faq.prod_faq_qa_answer}}/>
                    </div>
                  </React.Fragment>    
                
              ))}
              
              {data.wordpressPage.childWordPressAcfProdFaq.prod_faq_disclaimer_links !=null &&
                <React.Fragment>
                  {data.wordpressPage.childWordPressAcfProdFaq.prod_faq_disclaimer_links.map((dlink,index) => (
                    <React.Fragment >
                      <Pdfviewer id={'pdfViewerDL'+index} className="pdfViewer" file={dlink.file.url.localFile.publicURL}/>
                    </React.Fragment>
                  ))}
                  <DisclaimerReplace
                    key={data.wordpressPage.wordpress_id+'dr'}
                    replacedLinks={data.wordpressPage.childWordPressAcfProdFaq.prod_faq_disclaimer_links}
                    disclaimer={data.wordpressPage.childWordPressAcfProdFaq.prod_faq_disclaimer}
                    pdfOpen={disclaimerPdfOpen}
                  />
                </React.Fragment>
              }
              {data.wordpressPage.childWordPressAcfProdFaq.prod_faq_disclaimer_links === null &&
                <React.Fragment>
                  <div className='fCol12 faqDisclaimer' dangerouslySetInnerHTML={{ __html: data.wordpressPage.childWordPressAcfProdFaq.prod_faq_disclaimer}}/>
                </React.Fragment>
              }
              
              </div>
            </div>
            
          </div>
          
          }
          {data.wordpressPage.childWordPressAcfProdFaq != null &&
          <div className="flex-container faqButton">
            <div className="fCol12 maxTablet">
            <button id="faqMore" className="faqMore" onClick={ faqMore }><Icon>close</Icon></button>
            
            </div>
          </div>
          }
        </section>
        {data.wordpressPage.acf.prod_videos !=null &&
        <section id="content-videos" className="content-videos secondaryTab">
          <div className="fCol12 productName" css={css` background-color: ${data.wordpressPage.acf.product_color}; `}>
            <div className="maxTablet">
              <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.product_name+' Videos'}} />
            </div>
          </div>
          <div className="fCol12 maxTablet flexContainer fjLeft">
            <div className="hide">
                {carouselSlidesData.length = 0}
            </div>
            {data.wordpressPage.acf.prod_videos !=null && data.wordpressPage.acf.prod_videos.map(video => (
              <React.Fragment>  
                <div className="hide">{carouselSlidesData.push({"title": video.video_title,"description": video.video_description, "url": video.video_select.source_url, "poster": video.video_poster.localFile.publicURL})}</div>
              </React.Fragment>  
            ))}
            <Carousel slides={carouselSlidesData} />
          </div>
        </section>
        }
        {data.wordpressPage.acf.prod_videos ==null && <section id="content-videos" className="content-videos secondaryTab"></section>}
        {data.wordpressPage.acf.prod_add_collateral != null &&
        <section id="content-collateral" className="content-collateral secondaryTab">
          <div className="fCol12 productName" css={css` background-color: ${data.wordpressPage.acf.product_color}; `}>
            <div className="maxTablet">
              <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.product_name+' Collateral'}} />
            </div>
          </div>
          {data.wordpressPage.acf.prod_add_collateral != null && data.wordpressPage.acf.prod_add_collateral.map((collateral,index) => (
            <div>
              <React.Fragment>
                <div className="grayBorderBottom">
                  <div className="flexContainer maxTablet">
                    <div className="fCol12 flexContainer faCenter">
                      <div className="dIcon">
                      {collateral.prod_upload_collateral != null &&
                        <button value={'pdfViewer'+index} /*cUrl={collateral.prod_upload_collateral.url.localFile.publicURL}*/ onClick={ pdfOpen }>
                          <Img className=""
                            sizes={collateral.prod_collateral_thumb.localFile.childImageSharp.sizes}
                          />
                              VIEW        
                        </button>
                      }
                      </div>
                      <div className='dName' dangerouslySetInnerHTML={{ __html: collateral.prod_collateral_name+'<br/>'+collateral.prod_collateral_name_line}}></div>
                      <div className='dButtons'>
                        {collateral.prod_collateral_order !== "" &&
                          <Link target="_blank" to={collateral.prod_collateral_order}>
                            <button className="grayButton">Order</button>              
                          </Link>
                        }
                        <Link>
                          <button value={'shareDialog'+index} id={'deviceShare'+index} type="button" className="grayButton" title={'Share '+collateral.prod_collateral_name} data-file={webURL+collateral.prod_upload_collateral.url.localFile.publicURL} onClick={openShareDialog}>Share</button>            
                        </Link>
                        
                        {collateral.prod_collateral_zip_download != null &&
                          <a onClick={saveAsPDF} data-file={collateral.prod_collateral_zip_download.url.localFile.publicURL}><button  className="grayButton">Download</button></a>
                        }

                      </div>
                    </div>
                  </div>
                </div>
                <Pdfviewer id={'pdfViewer'+index} className="pdfViewer" file={collateral.prod_upload_collateral.url.localFile.publicURL}/>
                <div id={'shareDialog'+index} className="share-dialog">
                  <button value={'shareDialog'+index} onClick={closeShareDialog} className="boxClose"></button>
                  <div className="inner">
                    <header>
                      <h3 className="dialog-title">Share {collateral.prod_collateral_name}</h3>
                    </header>
                    <button value={'shareDialog'+index} className="close-button" onClick={closeShareDialog}></button>
                    <div className="targets">
                      <a className="button" href={'mailto:?body='+webURL+collateral.prod_upload_collateral.url.localFile.publicURL}>
                        <svg>
                          <use href="#email"></use>
                        </svg>
                        <span>Email</span>
                      </a>
                    </div>
                  </div>
                </div>
              </React.Fragment>    
            </div>
            
          ))}
          {/*data.wordpressPage.acf.prod_add_collateral != null && data.wordpressPage.acf.prod_add_collateral.map(collateral => (
            <div>
              <React.Fragment>
                <div className="grayBorderBottom">
                  <div className="flexContainer maxTablet">
                    <div className="fCol12 flexContainer faCenter">
                      <div className="dIcon">
                      {collateral.prod_upload_collateral != null &&
                        <Link to={collateral.prod_upload_collateral.url.localFile.publicURL}>
                          <Img className=""
                            sizes={collateral.prod_collateral_thumb.localFile.childImageSharp.sizes}
                          />
                              VIEW        
                        </Link>
                      }
                      </div>
                      <div className='dName' dangerouslySetInnerHTML={{ __html: collateral.prod_collateral_name+'<br/>'+collateral.prod_collateral_name_line}}></div>
                      <div className='dButtons'>
                        {collateral.prod_collateral_order !== "" &&
                          <Link target="_blank" to={collateral.prod_collateral_order}>
                            <button className="grayButton">Order</button>              
                          </Link>
                        }
                        <Link>
                          <button className="grayButton">Share</button>              
                        </Link>
                          <Link download target="_blank" to={collateral.prod_upload_collateral.url.localFile.publicURL}>
                            <button className="grayButton">Download</button>              
                          </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>    
            </div>
          ))*/}
          
        </section>
        }
        {data.wordpressPage.acf.prod_add_collateral === null &&
          <section id="content-collateral" className="content-collateral secondaryTab"></section>
        }
        <section id="content-products" className="content-products secondaryTab">
        <div className="fCol12 productName" css={css` background-color: ${data.wordpressPage.acf.product_color}; `}>
          <div className="maxTablet">
            <h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.parent_element.acf.brand_name+' Products'}} />
          </div>
        </div>
        <div className="hide">
        {mineralized.length = 0}
        {basic.length = 0}
        </div>
        <div className="fCol12 productLists">
        <div className="hide">
          {data.allWordpressPage.edges.map(product =>(
            <React.Fragment>
              {product.node.acf.product_class.value === 'm' &&
                mineralized.push(product.node.title)
              }
              {product.node.acf.product_class.value === 'b' &&
                basic.push(product.node.title)
              }
            </React.Fragment>
          ))}
        </div>
        {mineralized.length !=0 &&
        <div className="productList">
          <ExpansionPanel className="fCol12 productPanel">
            <ExpansionPanelSummary
              aria-controls={'product-panel'}
              id={'product-panel-header'}
            >
              <h3 className="categoryHeader" css={css` background: #817a68 !important; `}>Mineralized Salt</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            <ul>
            {data.allWordpressPage.edges.map(product =>(
              <React.Fragment>
                {product.node.acf.product_class.value === 'm' &&
                <React.Fragment>
                  {product.node.wordpress_id !== 110 &&
                    
                    
                    
                    


                    /*<Link to={`/${data.wordpressPage.parent_element.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/products/${product.node.slug}/`}>
                    <li css={css` border-left: 9px solid ${product.node.acf.product_color}; `}>
                      <div>
                        <h3 css={css` color: ${data.wordpressPage.parent_element.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name }} />
                        <div className="description">{product.node.acf.product_excerpt}</div>
                      </div>
                    </li>
                    </Link>*/


                    <li key={'productList'+product.node.id} css={css` border-left: 9px solid ${product.node.acf.product_color}; `}>
                    <Link className="productInfo" to={`/${data.wordpressPage.parent_element.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/products/${product.node.slug}/`}>
                      <div>
                        <h3 css={css` color: ${data.wordpressPage.parent_element.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name }} />
                        <div className="description">{product.node.acf.product_excerpt}</div>
                      </div>
                    </Link>
                    <AuthUserContext.Consumer>
                      {authUser =>
                        authUser ? (
                          <React.Fragment>
                            <div id={'mainPortfolioAddportfolioPopup'+product.node.id} className="portfolioAdd" pbutton={'portfolioPopup'+product.node.id} onClick={portfolioFormOpen}>
                              <span className="icon-star-unfill">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </span>
                              <span className="icon-star-full">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </span>
                              <span className="icon-select-bars"></span>
                            </div>
                            <PortfolioProduct key={'portfolioPopup'+product.node.id} productId={'portfolioPopup'+product.node.id} productTitle={product.node.acf.product_name.replace('<span class="reg"></span>','®')} brandName={data.wordpressPage.parent_element.acf.brand_name.replace('<span class="reg"></span>','®')} productLink={`/${data.wordpressPage.parent_element.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/products/${product.node.slug}`}/>
                          </React.Fragment>
                        ) : null
                    }           
                    </AuthUserContext.Consumer>
                    </li>
                  }
                </React.Fragment>
                }
              </React.Fragment>
            ))}
            </ul>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        }
        {basic.length !=0 &&
        <div className="productList">
          <ExpansionPanel className="fCol12 productPanel">
            <ExpansionPanelSummary
              aria-controls={'product-panel'}
              id={'product-panel-header'}
            >
              <h3 className="categoryHeader" css={css` background: #A8B0B4 !important; `}>Basic Salt</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
          <ul>
          {data.allWordpressPage.edges.map(product =>(
            <React.Fragment>
              {product.node.acf.product_class.value === 'b' &&
              <li key={'productList'+product.node.id} css={css` border-left: 9px solid ${product.node.acf.product_color}; `}>
              <Link className="productInfo" to={`/${data.wordpressPage.parent_element.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/products/${product.node.slug}/`}>
                <div>
                  <h3 css={css` color: ${data.wordpressPage.parent_element.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name }} />
                  <div className="description">{product.node.acf.product_excerpt}</div>
                </div>
              </Link>
              <AuthUserContext.Consumer>
                {authUser =>
                  authUser ? (
                    <React.Fragment>
                      <div id={'mainPortfolioAddportfolioPopup'+product.node.id} className="portfolioAdd" pbutton={'portfolioPopup'+product.node.id} onClick={portfolioFormOpen}>
                        <span className="icon-star-unfill">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </span>
                        <span className="icon-star-full">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </span>
                        <span className="icon-select-bars"></span>
                      </div>
                      <PortfolioProduct key={'portfolioPopup'+product.node.id} productId={'portfolioPopup'+product.node.id} productTitle={product.node.acf.product_name.replace('<span class="reg"></span>','®')} brandName={data.wordpressPage.parent_element.acf.brand_name.replace('<span class="reg"></span>','®')} productLink={`/${data.wordpressPage.parent_element.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/products/${product.node.slug}`}/>
                    </React.Fragment>
                  ) : null
                }           
              </AuthUserContext.Consumer>
              </li>
              }
            </React.Fragment>
          ))}
          </ul>
          </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        }
        {data.wordpressPage.parent_element.acf.master_tether_selector != 'master' && data.wordpressPage.parent_element.acf.master_tether_selector != 'tether' &&
        <div className="productList noCat">
          <ul>
          {data.allWordpressPage.edges.map(product =>(
            <React.Fragment>
              {product.node.acf.product_class.value === 'none' &&
              <li key={'productList'+product.node.id} css={css` border-left: 9px solid ${product.node.acf.product_color}; `}>
              <Link className="productInfo" to={`/${data.wordpressPage.parent_element.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/products/${product.node.slug}/`}>
                <div>
                  <h3 css={css` color: ${data.wordpressPage.parent_element.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name }} />
                  <div className="description">{product.node.acf.product_excerpt}</div>
                </div>
              </Link>
              <AuthUserContext.Consumer>
                {authUser =>
                  authUser ? (
                    <React.Fragment>
                      <div id={'mainPortfolioAddportfolioPopup'+product.node.id} className="portfolioAdd" pbutton={'portfolioPopup'+product.node.id} onClick={portfolioFormOpen}>
                        <span className="icon-star-unfill">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </span>
                        <span className="icon-star-full">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </span>
                        <span className="icon-select-bars"></span>
                      </div>
                      <PortfolioProduct key={'portfolioPopup'+product.node.id} productId={'portfolioPopup'+product.node.id} productTitle={product.node.acf.product_name.replace('<span class="reg"></span>','®')} brandName={data.wordpressPage.parent_element.acf.brand_name.replace('<span class="reg"></span>','®')} productLink={`/${data.wordpressPage.parent_element.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.parent_element.slug}/${data.wordpressPage.parent_element.slug}/products/${product.node.slug}`}/>
                    </React.Fragment>
                  ) : null
                }           
              </AuthUserContext.Consumer>
              </li>
              }
            </React.Fragment>
          ))}
          </ul>
        </div>
        }
        {data.wordpressPage.parent_element.acf.master_tether_selector === 'master' &&
        <React.Fragment>
          <MasterTetherList masterID={data.wordpressPage.parent_element.wordpress_id} tetherID={data.wordpressPage.parent_element.acf.tether_brand}/>
        </React.Fragment>
        }
        {data.wordpressPage.parent_element.acf.master_tether_selector === 'tether' &&
        <React.Fragment>
          <MasterTetherList masterID={data.wordpressPage.parent_element.acf.master_brand} tetherID={data.wordpressPage.parent_element.wordpress_id}/>
        </React.Fragment>
        }
        </div>
      </section>
      </div>
      <AuthUserContext.Consumer>
        {authUser =>
          authUser ? (
            <React.Fragment>
              <PortfolioProduct key={'portfolioPopupOg'+data.wordpressPage.id} productId={'portfolioPopupOg'+data.wordpressPage.id} productTitle={data.wordpressPage.acf.product_name.replace('<span class="reg"></span>','®')} brandName={data.wordpressPage.parent_element.acf.brand_name.replace('<span class="reg"></span>','®')} productLink={data.wordpressPage.parent_element.path+'products/'+data.wordpressPage.slug}/>
            </React.Fragment>
          ) : null
        }           
      </AuthUserContext.Consumer>
      
      <footer className="productTabs" css={css` border-color: ${data.wordpressPage.parent_element.acf.brand_color}; `}>
      
        <Paper square >
          <Tabs
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="icon label tabs example"
          >
            <Tab id="homeTab" className="icon-brand-home active tab" label="Product Home" data-tab="homeTab" data-content="content-home" onClick={ tabNavigation }/>
            <Tab id="specsTab" className="icon-specifications tab" label="Specifications" data-tab="specsTab" data-content="content-spec" onClick={ tabNavigation }/>
            <Tab id="proofTab" className="icon-the-proof disabled tab" data-tab="proofTab" data-content="content-proof" label="The Proof"/>
            {data.wordpressPage.acf.prod_add_data_sheets != null &&
              <Tab id="dataTab" className="icon-data-sheets tab" label="Data Sheets" data-tab="dataTab" data-content="content-data" onClick={ tabNavigation }/>
            }
            {data.wordpressPage.acf.prod_add_data_sheets === null &&
              <Tab id="dataTab" className="icon-data-sheets tab disabled" label="Data Sheets"/>
            }
            <Tab id="addTab" className="icon-additional tab" label="Additional" data-tab="addTab" data-content="additional-content" onClick={ tabNavigation }/>
          </Tabs>
        </Paper>
        <div id="additional-content">
          <Tabs>
            <Tab id="proofTab2" className="icon-the-proof tab disabled" label="The Proof"/>
            {data.wordpressPage.acf.prod_add_data_sheets != null &&
              <Tab id="dataTab2" className="icon-data-sheets tab" label="Data Sheets" data-tab="dataTab2" data-content="content-data" data-mob-tab="true" onClick={ tabNavigation }/>
            }
            {data.wordpressPage.acf.prod_add_data_sheets === null &&
              <Tab id="dataTab2" className="icon-data-sheets tab disabled" label="Data Sheets"/>
            }
            {data.wordpressPage.acf.prod_img_galy !=null &&
              <Tab id="galleryTab" className="icon-imagery tab" label="Product Imagery" data-tab="galleryTab" data-content="content-gallery" data-mob-tab="true" onClick={ tabNavigation }/>
            }
            {data.wordpressPage.acf.prod_img_galy ==null &&
              <Tab id="galleryTab" className="icon-imagery tab disabled" label="Product Imagery"/>
            }
            <Tab id="usageTab" className="icon-usage tab" label="Usage" data-tab="usageTab" data-content="content-usage" data-mob-tab="true" onClick={ tabNavigation }/>
            {data.wordpressPage.acf.prod_videos !=null &&
              <Tab id="videoTab" className="icon-videos tab" label="Videos" data-tab="videoTab" data-content="content-videos" data-mob-tab="true" onClick={ tabNavigation }/>
            }
            {data.wordpressPage.acf.prod_videos ==null &&
              <Tab id="videoTab" className="icon-videos tab disabled" label="Videos"/>
            }
            {data.wordpressPage.acf.prod_add_collateral != null &&
              <Tab id="collateralTab" className="icon-collateral tab" label="Collateral" data-tab="collateralTab" data-content="content-collateral" data-mob-tab="true" onClick={ tabNavigation }/>
            }
            {data.wordpressPage.acf.prod_add_collateral === null &&
              <Tab id="collateralTab" className="icon-collateral tab disabled" label="Collateral"/>
            }
            <Tab id="productsTab" className="icon-products tab" label="Brand Products" data-tab="productsTab" data-content="content-products" data-mob-tab="true" onClick={ tabNavigation }/>
          </Tabs>
          <button id="addMenuClose" onClick={ closeMenu }><Icon>close</Icon></button>
        </div>
      </footer>
      <svg className="hidden">
  <defs>
    <symbol id="share-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-share"><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><polyline points="16 6 12 2 8 6"></polyline><line x1="12" y1="2" x2="12" y2="15"></line></symbol>
    
    <symbol id="facebook" viewBox="0 0 24 24" fill="#3b5998" stroke="#3b5998" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></symbol>
    
    <symbol id="twitter" viewBox="0 0 24 24" fill="#1da1f2" stroke="#1da1f2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></symbol>
    
    <symbol id="email" viewBox="0 0 24 24" fill="#000" stroke="#E2E4E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></symbol>
    
    <symbol id="linkedin" viewBox="0 0 24 24" fill="#0077B5" stroke="#0077B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></symbol>
    
    <symbol id="close" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x-square"><rect x="3" y="3" width="25" height="25" rx="2" ry="2"></rect><line x1="9" y1="9" x2="15" y2="15"></line><line x1="15" y1="9" x2="9" y2="15"></line></symbol>
  </defs>
</svg>
    </Layout>
)}

  export default ProdTemplate
  export const query = graphql`
    query($id: Int!, $parent_id: Int!) {
      site {
        siteMetadata {
          siteUrl
          title
          menuLinks {
            link
            name
          }
        }
      }
      allWordpressPage(sort: {fields: acf___product_name},filter: {wordpress_parent: {eq: $parent_id}}) {
        edges {
          node {
            id
            title
            acf {
              product_color
              product_name
              product_excerpt
              product_class {
                label
                value
              }
            }
            slug
            excerpt
            wordpress_id
          }
        }
      }
      wordpressPage(wordpress_id: { eq: $id }) {
        title
        excerpt
        content
        slug
        parent_element {
          title
          slug
          path
          wordpress_id
          acf {
            brand_name
            master_tether_selector
            tether_brand
            master_brand

          }
          parent_element {
            title
            slug
            parent_element {
              title
              slug
            }
          }
          acf {
            brand_name
            brand_color
            brand_logo {
              localFile {
                childImageSharp {
                  sizes(maxWidth:1200) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
            brand_hero {
              localFile {
                childImageSharp {
                  sizes(maxWidth:1551) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }            
          }
        }
        acf {
          prod_hero_opacity
          prod_brand_logo_override {
            localFile {
              childImageSharp {
                sizes(maxWidth:1200) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          prod_hero_override {
            localFile {
              childImageSharp {
                sizes(maxWidth:1200) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          product_bag {
            localFile {
              childImageSharp {
                sizes(maxWidth:1200) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          prod_brand_logo_position {
            label
            value
          }
          product_name
          type_of_page
          product_color
          product_class {
            label
            value
          }
          product_copy
          product_ingred_headline
          product_ingred
          product_points_rp {
            product_point
          }
          product_points_fp
          prod_sizes_page {
            prod_fets_header
            prod_select_icons {
              prod_icon
              prod_icon_txt
            }
            prod_fet_warning
          }
          product_show_size
          prod_species_page {
            prod_fets_header
            prod_select_icons {
              prod_icon
              prod_icon_txt
              prod_icon_status
            }
            prod_fet_warning
          }
          prod_input_specs_page {
            prod_table_header 
            prod_tbl_two_rep {
              prod_tbl_two_row {
                prod_tbl_two_frst_col
                prod_tbl_two_sec_col
              }
            }
          }
          prod_pkg_tables {
            prod_pkg_header
            prod_pkg_table {
              header {
                c
              }
              body {
                c
              }
            }
          }
          prod_add_data_sheets {
            prod_data_sheet_name
            prod_data_sheet_name_line
            prod_data_sheet_name_line_3
            prod_upload_data_sheet {
              link
              filename
              subtype
              url {
                localFile {
                  publicURL
                }
              }
            }
            prod_data_sheet_zip_download {
              filename
              url {
                localFile {
                  publicURL
                  url
                  originalSourceUrl
                }
              }
            }
          }
          prod_img_galy_portal
          prod_img_galy {
            localFile {
              childImageSharp {
                sizes(maxWidth:1200) {
                  ...GatsbyImageSharpSizes
                }
              }
              url
              absolutePath
              publicURL
            }
            caption
          }
          prod_add_collateral {
            prod_collateral_name
            prod_collateral_name_line
            prod_collateral_order
            prod_upload_collateral {
              link
              filename
              subtype
              url {
                localFile {
                  publicURL
                  absolutePath
                  relativePath
                }
              }
            }
            prod_collateral_zip_download {
              filename
              url {
                localFile {
                  publicURL
                  url
                  originalSourceUrl
                }
              }
            }
            prod_collateral_thumb {
              media_type
              localFile {
                childImageSharp {
                  sizes(maxWidth:1200) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
          prod_videos {
            video_description
            video_title
            video_select {
              source_url
              localFile {
                publicURL
              }
            }
            video_poster {
              localFile {
                childImageSharp {
                  sizes(maxWidth:1200) {
                    ...GatsbyImageSharpSizes
                  }
                }
                url
                absolutePath
                publicURL
              }
            }
          }
        }
        childWordPressAcfProdUsgInstructions {
          prod_usg_instructions_copy
          prod_usg_instructions_header
        }
        childWordPressAcfProdUsageTable {
          prod_usage_table_header
          prod_usage_table_before
          prod_usage_table_table {
            header {
              c
            }
            body {
              c
            }
          }
          prod_usage_table_after
        }
        
        childWordPressAcfProdWarnLbl {
          prod_warn_lbl_copy
          prod_warn_lbl_header          
        }
        childWordPressAcfProdFaq {
          prod_faq_header
          prod_faq_qa {
            prod_faq_qa_answer
            prod_faq_qa_question
          }
          prod_faq_disclaimer
          prod_faq_disclaimer_links {
            file {
              url {
                localFile {
                  publicURL
                }
              }
            }
            link_text
            replace
          }
        }
        yoast_meta {
          yoast_wpseo_title
          yoast_wpseo_metadesc
        }
      }
    }
`